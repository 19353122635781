// Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito');
// @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// my extra
.rounded-top-start-2{border-top-left-radius: 0.3rem !important;}
.rounded-bootom-start-2{border-bottom-left-radius: 0.3rem !important;}
